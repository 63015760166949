@import '@styles/mixins.style';

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@keyframes backdrop-fade {
  from {
    background: transparent;
  }
  to {
    background: rgba(37, 45, 56, 0.86);
  }
}

dialog {
  background-color: var(--modal-background);
  box-shadow: var(--ra-elevation-heavy);
  border-radius: var(--ra-border-radius-xl);
  max-height: 90%;
  max-width: 85vw;

  @include breakpoint(md) {
    max-width: 80vw;
  }

  @include breakpoint(lg) {
    max-width: 75vw;
  }

  &.close {
    animation: backdrop-fade 300ms ease backwards;
    animation-direction: reverse;
  }

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    max-width: 100vw;
    max-height: 100vh;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
}

.ra-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--ra-spacing-4);
    background-color: var(--modal-background);
    position: sticky;
    top: 0;
    width: 100%;
    gap: var(--ra-spacing-4);
    transform: translateY(-1.2em);
    padding-top: 1em;
    padding-bottom: var(--ra-spacing-2);
    z-index: 10;
  }

  &__content {
    width: 100%;
    height: auto;
    position: relative;
  }

  &__body {
    padding: 1px;
  }

  &.fullscreen {
    .ra-modal__header {
      position: relative;
      top: 0px;
      padding-top: 0;
    }
  }
}

dialog[open] {
  outline: none;
  z-index: 10000;
  opacity: 1;
  border-color: var(--modal-background);
  animation: fadein 350ms ease-in-out forwards;

  &::backdrop {
    animation: backdrop-fade 350ms ease forwards;
  }

  .ra-modal__overlay {
    opacity: 1;
    pointer-events: inherit;
  }

  .ra-modal__dialog {
    opacity: 1;
    pointer-events: visible;
    transition-delay: 0.75s;
  }
}
